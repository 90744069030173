import React from 'react';
import { ProductionContent } from './fabricManufacturing/ProductionContent';
import { ManufacturingContent } from './clothManufacturing/ManufacturingContent';
import { VENDOR } from '../../../constants';
import { getLocalStorageItem } from '../../../utils';

export const Manufacturing = () => {
  const vendorType = getLocalStorageItem('tags')[0];
  return <>{(vendorType === VENDOR.FABRIC || vendorType===VENDOR.DYE) ? <ProductionContent /> : <ManufacturingContent />}</>;
};
