import React from 'react';
import { BoxIcon, DeleteRedIcon } from '../../assets/icons';
import { DISPLAY_TEXTS, POPUP_TYPE } from '../../constants';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../redux';
import { addPackageToDispatch } from '../../service';
import { popup } from '../../utils';

export const BlpTilesDispatch = ({ tile, dispatch_id, dispatch_status, setReload }) => {
  const dispatch = useDispatch();
  const isUniformSKU = tile.SKU_Details.every((sku) => sku.SKU === tile.SKU_Details[0].SKU);
  const tileClass = isUniformSKU ? 'blue-shade' : 'pink-shade';

  const removeTile = () => {
    dispatch(setLoaderVisibility(true));
    addPackageToDispatch({
      data: {
        dispatch_id: dispatch_id,
        package_name: tile.BLP,
        type: 'remove',
      },
    })
      .then((response) => {
        if (response.message.includes('removed')) {
          popup(POPUP_TYPE.SUCCESS, response.message);
        } else {
          popup(POPUP_TYPE.ERR, response.message);
        }
        setReload(true);
        dispatch(setLoaderVisibility(false));
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  return (
    <div className="col-xl-2 col-lg-4 col-6 custom-col pt-2">
      <div className="w-100 h-100 bg-white tiles">
        <div className={`w-100 d-flex align-items-center justify-content-center ${tileClass}`}>
          <span className="id-info">
            {DISPLAY_TEXTS.BLP_TILES[0]}
            <span>{tile.BLP}</span>
          </span>
        </div>
        <div className="w-100 d-flex align-items-center justify-content-center main-content-box">
          <div className="img-box">
            <BoxIcon />
          </div>
          <div className="sku-number flex-column d-flex align-items-start justify-content-start">
            <h6 className="m-0">
              {DISPLAY_TEXTS.BLP_TILES[1]}
              <span>{tile.SKU}</span>
            </h6>
            <p className="m-0">
              {DISPLAY_TEXTS.BLP_TILES[2]} {tile.Batch}
            </p>
          </div>
          {dispatch_status === 'draft' && (
            <div className="delete-icon-dispatch">
              <DeleteRedIcon onClick={removeTile} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
