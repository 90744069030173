import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import {
  DashboardContent,
  DispatchContent,
  PoContent,
  ReportsContent,
  ReceiptContent,
  BillsContent,
  DispatchDetail,
  ReceiptDetails,
  BillsDetails,
  PoDetails,
  MixBox,
  CreateDispatch,
  FabricDispatchDetail,
  PackageBlpOverview,
  YarnDispatchDetail,
  DispatchBlpOverview,
  Error404,
  Login,
  Manufacturing,
  ManufacturingDetails,
  PackageDetail,
} from './components/pages';
import { ROUTES } from './constants';
import { Layout } from './components/common/Layout';
import { getLocalStorageItem } from './utils';

const VendorAuthGuard = ({ children }) => {
  const authToken = getLocalStorageItem('auth_token');
  return authToken === null ? <Navigate to={ROUTES.LOGIN} replace /> : children;
};

const router = createBrowserRouter([
  {
    path: ROUTES.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTES.HOME,
    element: <Layout />,
    children: [
      {
        path: ROUTES.DASHBOARD,
        element: (
          <VendorAuthGuard>
            <DashboardContent />
          </VendorAuthGuard>
        ),
      },
      {
        path: ROUTES.PURCHASE_ORDER,
        element: (
          <VendorAuthGuard>
            <PoContent />
          </VendorAuthGuard>
        ),
      },
      {
        path: `${ROUTES.PURCHASE_ORDER}${ROUTES.URL}`,
        element: (
          <VendorAuthGuard>
            <PoDetails />
          </VendorAuthGuard>
        ),
      },
      {
        path: ROUTES.BILLS,
        element: (
          <VendorAuthGuard>
            <BillsContent />
          </VendorAuthGuard>
        ),
      },
      {
        path: `${ROUTES.BILLS}${ROUTES.URL}`,
        element: (
          <VendorAuthGuard>
            <BillsDetails />
          </VendorAuthGuard>
        ),
      },
      {
        path: ROUTES.RECEIPT,
        element: (
          <VendorAuthGuard>
            <ReceiptContent />
          </VendorAuthGuard>
        ),
      },
      {
        path: `${ROUTES.RECEIPT}${ROUTES.URL}`,
        element: (
          <VendorAuthGuard>
            <ReceiptDetails />
          </VendorAuthGuard>
        ),
      },
      {
        path: ROUTES.MANUFACTURING,
        element: (
          <VendorAuthGuard>
            <Manufacturing />
          </VendorAuthGuard>
        ),
      },
      {
        path: `${ROUTES.MANUFACTURING}${ROUTES.URL}`,
        element: (
          <VendorAuthGuard>
            <ManufacturingDetails />
          </VendorAuthGuard>
        ),
      },
      {
        path: ROUTES.PACKAGE,
        element: (
          <VendorAuthGuard>
            <PackageDetail />
          </VendorAuthGuard>
        ),
      },
      {
        path: ROUTES.PACKAGE_BLP_OVERVIEW,
        element: (
          <VendorAuthGuard>
            <PackageBlpOverview />
          </VendorAuthGuard>
        ),
      },
      {
        path: ROUTES.DISPATCH_BLP_OVERVIEW,
        element: (
          <VendorAuthGuard>
            <DispatchBlpOverview />
          </VendorAuthGuard>
        ),
      },
      {
        path: ROUTES.ADD_MIX_BOX,
        element: (
          <VendorAuthGuard>
            <MixBox />
          </VendorAuthGuard>
        ),
      },
      {
        path: ROUTES.DISPATCH,
        element: (
          <VendorAuthGuard>
            <DispatchContent />
          </VendorAuthGuard>
        ),
      },
      {
        path: ROUTES.CREATE_DISPATCH,
        element: (
          <VendorAuthGuard>
            <CreateDispatch />
          </VendorAuthGuard>
        ),
      },
      {
        path: `${ROUTES.DISPATCH_DETAILS}${ROUTES.URL}`,
        element: (
          <VendorAuthGuard>
            <DispatchDetail />
          </VendorAuthGuard>
        ),
      },
      {
        path: `${ROUTES.FABRIC_DISPATCH_DETAIL}${ROUTES.URL}`,
        element: (
          <VendorAuthGuard>
            <FabricDispatchDetail />
          </VendorAuthGuard>
        ),
      },
      {
        path: `${ROUTES.YARN_DISPATCH_DETAIL}${ROUTES.URL}`,
        element: (
          <VendorAuthGuard>
            <YarnDispatchDetail />
          </VendorAuthGuard>
        ),
      },
      {
        path: ROUTES.REPORTS,
        element: (
          <VendorAuthGuard>
            <ReportsContent />
          </VendorAuthGuard>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <Error404 />,
  },
]);

export default function AppRouter() {
  return <RouterProvider router={router} />;
}
