// import React from 'react';
// import { Link, useNavigate } from 'react-router-dom';

// export const TitleBar = ({ title }) => {
//   const navigate = useNavigate();
//   const handleTitleClick = (index) => {
//     index + 1 < title.length  && !title[index].route ? navigate(-1) : navigate(title[index].route);
//   };

//   return (
//     <div className="title-bar bg-white w-100 mb-3" style={{ cursor: 'pointer' }}>
//       <h4 className="mb-0">
//         {title.map((sub, index) =>
//           sub.text !== '' ? (
//             <span key={index} onClick={() => handleTitleClick(index)}>
//               <span> {index > 0 ? ' > ' : ''}</span>
//               {index + 1 < title.length ? <Link className="breadcrumb">{sub.text}</Link> : sub.text}
//             </span>
//           ) : (
//             ''
//           ),
//         )}
//       </h4>
//     </div>
//   );
// };

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

export const TitleBar = ({ title }) => {
  const navigate = useNavigate();
  const handleTitleClick = (index) => {
    index + 1 < title.length && title[index].route !== '' ? navigate(-1) : '';
  };

  return (
    <div className="title-bar bg-white w-100 mb-3">
      <h4 className="mb-0">
        {title.map((sub, index) =>
          sub.text !== '' ? (
            <span key={index} onClick={() => handleTitleClick(index)} style={{ cursor: index > 1 ? 'pointer' : 'default' }}>
              <span> {index > 0 ? ' > ' : ''}</span>
              {index + 1 < title.length && title[index].route !== '' ? (
                <Link className="breadcrumb">{sub.text}</Link>
              ) : (
                sub.text
              )}
            </span>
          ) : (
            ''
          ),
        )}
      </h4>
    </div>
  );
};
