import React, { useEffect, useRef, useState } from 'react';
import { DetailBox, Table, TitleBar } from '../../common';
import {
  TITLES,
  RECEIPT_INNER_COLUMN,
  ROUTES,
  BUTTON,
  POPUP_MSG,
  POPUP_TYPE,
  LOCAL_STORAGE,
} from '../../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  receiptDetailCompiler,
  receiptDetailsBox,
} from '../../../utils/dataCompiler/receiptDataCompiler';
import { PopUpBox } from '../../shared/PopUpBox';
import { Button } from '../../shared';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import {
  changeDispatchStatus,
  packageCreationDyeing,
  updateReceiptQty,
  validateDispatch,
  vendorReceipt,
} from '../../../service';
import { getLocalStorageItem, popup } from '../../../utils';
import { printLabelPackage } from '../../../service/package';
import PrintLabelModal from './PrintLabelModal';
import { useDebounce } from '../../../hooks';

export const ReceiptDetails = () => {
  const { state } = useLocation(location.state);
  const navigate = useNavigate();
  const [popUp, setPopUp] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validate, setValidate] = useState(false);
  const [popUpData, setPopUpData] = useState('');
  const [receiptData, setReceiptsData] = useState(state);
  const dispatch = useDispatch();
  const data = receiptDetailCompiler({ moveIds: receiptData?.move_ids, state: receiptData?.state });
  const canShowValidateButton = shouldShowValidateButton(data);

  console.log("RD",receiptData)

  let column = RECEIPT_INNER_COLUMN.filter(
    (col) =>
      (state?.state === 'done' ? col.name !== 'Action' : true) &&
      (state?.partner_id?.name === 'ESUN' ? true : col.name !== 'Rolls'),
  );

  useEffect(() => {
    if (!state) navigate(ROUTES.RECEIPT);

    reloadData();
  }, [state]);

  const handlePrintLabelClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const reloadData = () => {
    vendorReceipt({
      data: {
        id: receiptData?.id,
      },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        response.data.length > 0
          ? setReceiptsData(response.data[0])
          : setReceiptsData(response.message);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const validateReceipt = () => {
    dispatch(setLoaderVisibility(true));
    validateDispatch({ data: { picking_id: state?.id } })
      .then((response) => {
        popup(POPUP_TYPE.SUCCESS, response.message);
        reloadData();
        navigate(ROUTES.RECEIPT);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const updateReceipt = (data) => {
    dispatch(setLoaderVisibility(true));
    updateReceiptQty({ data: { ...data } })
      .then((response) => {
        if (response.data.length) popup(POPUP_TYPE.SUCCESS, response.message);
        else popup(POPUP_TYPE.ERR, POPUP_MSG.INVALID_QTY);
        reloadData();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const handleSubmitModal = (payload) => {
    printLabelPackage({
      data: payload,
    })
      .then((response) => {
        if (response.data.length > 0) {
          const url = response.data[0].split('?')[0];
          window.open(url, '_blank', 'noreferrer');
          navigate(ROUTES.RECEIPT);
        }
        dispatch(setLoaderVisibility(false));
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const handleDispatch = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    changeDispatchStatus({
      data: { dispatch_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        reloadData();
        navigate(ROUTES.RECEIPT);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
  }, 1000);

  const createPackage = () => {
    dispatch(setLoaderVisibility(true));
    packageCreationDyeing({ data: { picking_id: [state?.id] } })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        reloadData();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.RECEIPT, route: ROUTES.RECEIPT },
          { text: state?.name, route: ROUTES.NULL },
        ]}
      />
      <div className="scroll-div">
        <div className="d-flex justify-content-end gap-2 mb-3">
          {canShowValidateButton && state?.vendor_id?.vendor_type !== 'dyeing' && (
            <Button
              clickHandler={validateReceipt}
              className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
              {BUTTON.VALIDATE}
            </Button>
          )}
          {getLocalStorageItem(LOCAL_STORAGE.VENDOR_TYPE) === 'dyeing' &&
            receiptData?.dispatch_status !== 'dispatched' &&
            receiptData?.dispatch_status !== 'received' && (
              <Button
                clickHandler={handleDispatch}
                className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
                {BUTTON.DISPATCH}
              </Button>
            )}
          {receiptData?.vendor_id &&
            receiptData?.vendor_id?.vendor_type === 'dyeing' &&
            (receiptData?.vendor_id !== false && receiptData?.state !== 'done' ? (
              <Button
                clickHandler={createPackage}
                className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
                {BUTTON.CREATE_PACKAGE_AND_VALIDATE}
              </Button>
            ) : (
              <Button
                clickHandler={handlePrintLabelClick}
                className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
                {BUTTON.PRINT_LABEL}
              </Button>
            ))}
        </div>
        <DetailBox colCount={2} colData={receiptData} dataCompiler={receiptDetailsBox} />
        <Table
          columns={column}
          initialData={data}
          PAGE_SIZE={4}
          colFilter={false}
          popUp={popUp}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
        />
        {validate && (
          <PopUpBox openPopUpBox={validate}>
            <ReceiptValidate
              apiCall={validateReceipt}
              setOpenPopUpBox={setValidate}
              id={receiptData.id}
            />
          </PopUpBox>
        )}
        {popUp && (
          <PopUpBox openPopUpBox={popUp}>
            <QtyUpdate apiCall={updateReceipt} setOpenPopUpBox={setPopUp} data={popUpData} />
          </PopUpBox>
        )}
        {isModalOpen && (
          <PrintLabelModal
            onClose={handleCloseModal}
            onSubmit={handleSubmitModal}
            packageData={state?.move_ids[0]?.packages_ids}
          />
        )}
      </div>
    </>
  );
};

const QtyUpdate = ({ apiCall, setOpenPopUpBox, data }) => {
  const [doneQty, setDoneQty] = useState('');
  const qtyRef = useRef();
  const closePopUp = () => {
    setOpenPopUpBox(false);
  };
  const handleApiCall = () => {
    if (doneQty > 0) {
      setOpenPopUpBox(false);
      const apiData = {
        move_id: data.id,
        done_qty: +doneQty,
      };
      apiCall(apiData);
    } else {
      popup(POPUP_TYPE.ERR, POPUP_MSG.INVALID_QTY);
    }
  };

  useEffect(() => {
    qtyRef.current.focus();
  }, []);

  return (
    <>
      <div className="d-flex flex-column m-1 ">
        <h4 className=" text-center text-special mb-3 mt-3 "> {POPUP_MSG.ENTER_QTY} </h4>
        <hr />
        <div className="w-100 modal-body py-0 d-flex flex-column  gap-3 justify-content-center align-items-center">
          <div className="d-flex justify-content-between w-100 ">
            <label className="ms-3">Quantity</label>
            <input
              type="number"
              className="ps-2 curve-border me-4"
              placeholder={data.received_quantity || ''}
              ref={qtyRef}
              onChange={(e) => {
                setDoneQty(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.UPDATE}
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};

const ReceiptValidate = ({ apiCall, setOpenPopUpBox, id }) => {
  const closePopUp = () => {
    setOpenPopUpBox(false);
  };
  const handleApiCall = () => {
    setOpenPopUpBox(false);
    apiCall({
      picking_id: id,
    });
  };
  return (
    <>
      <div className="d-flex m-1 ">
        <div className="w-25 modal-body py-0 d-flex justify-content-center align-items-center">
          <span className="d-flex justify-content-center align-items-center bg-info badge">!</span>
        </div>
        <div className="w-75 modal-body py-0">
          <h4 className=" text-center text-special mb-3 "> {POPUP_MSG.SURE}</h4>
          <p className="text-center text-special placeholder-color ">{POPUP_MSG.QTY_WILL_SAVE}</p>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CONFIRM}
          </Button>{' '}
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};

const shouldShowValidateButton = (data) => {
  for (const item of data) {
    return item.state === 'Assigned' ? true : false;
  }
};
