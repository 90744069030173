import React from 'react';
import BarcodeReader from 'react-barcode-reader';
import CameraScanner from './CameraScanner';

export const Scanner = ({ setScanData, setIsCamScannerOpen, isCamScannerOpen = true }) => {
  const handleScan = (data) => {
    if (data) {
      setScanData(data) && setIsCamScannerOpen(false);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const isCameraScan = localStorage.getItem('isCameraScan');
  const isCameraScanBool = isCameraScan === 'true';

  return (
    <div>
        <div className="me-5 spinner-border text-secondary" role="status">
          <span className="sr-only"></span>
        </div>
      {isCameraScanBool ? (
        <CameraScanner
          isCamScannerOpen={isCamScannerOpen}
          setIsCamScannerOpen={setIsCamScannerOpen}
          handleScan={handleScan}
          handleError={handleError}
        />
      ) : (
        <BarcodeReader onError={handleError} onScan={handleScan} />
      )}
    </div>
  );
};
