import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BUTTON, POPUP_TYPE, ROUTES, TITLES } from '../../../constants';
import DetailTable from '../../common/DetailTable';
import { Button } from '../../shared';
import { DetailBox, TitleBar } from '../../common';
import { packageDetailsBox } from '../../../utils/dataCompiler/packageDataCompiler';
import { printLabelPackage } from '../../../service/package';
import { popup } from '../../../utils';
import { setLoaderVisibility } from '../../../redux';
import { useDispatch } from 'react-redux';
import PrintLabelModal from './PrintLabelModal';

export const PackageBlpOverview = () => {
  const { state } = useLocation(location.state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const columns = ['SKU', 'Batch No.', 'Quantity'];

  const handlePrintLabelClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmitModal = (payload) => {
    // Make an API call or perform other actions with the payload
    printLabelPackage({
      data: payload,
    })
      .then((response) => {
        if (response.data.length > 0) {
          dispatch(setLoaderVisibility(false));
          const url= response.data[0].split('?')[0];
          window.open(url, '_blank', 'noreferrer');
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };
  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.PACKAGE, route: ROUTES.PACKAGE },
          { text: state?.data?.BLP, route: ROUTES.NULL },
        ]}
      />
      <div className="scroll-div">
        <div className="d-flex justify-content-end mb-3">
          <Button
            clickHandler={handlePrintLabelClick}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.PRINT_LABEL}
          </Button>
        </div>
        <DetailBox colCount={2} colData={state?.data?.data} dataCompiler={packageDetailsBox} />
        <DetailTable
          title={'"' + state?.data?.BLP + '"' + ' Overview'}
          columns={columns}
          data={state?.data?.SKU_Details}
        />
      </div>
      {isModalOpen && (
        <PrintLabelModal
          onClose={handleCloseModal}
          onSubmit={handleSubmitModal}
          packageData={state?.data?.data?.id}
        />
      )}
    </>
  );
};
