import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { Header, Sidebar } from './index.js';
import { ROUTES, LOCAL_STORAGE } from '../../constants';
import { getLocalStorageItem } from '../../utils/localStorage.js';
import { Login } from '../pages/index.js';
// import PropTypes from 'prop-types';

export const Layout = () => {
  const [currentDashboardRoute, setCurrentDashboardRoute] = useState(ROUTES.DASHBOARD);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (getLocalStorageItem(LOCAL_STORAGE.AUTH_TOKEN) !== null && location.pathname === ROUTES.HOME)
      navigate(ROUTES.DASHBOARD);
    setCurrentDashboardRoute(location.pathname);
    if (getLocalStorageItem(LOCAL_STORAGE.AUTH_TOKEN) === null) {
      navigate(ROUTES.LOGIN);
      setCurrentDashboardRoute(location.LOGIN);
    }
  }, [location.pathname]);

  const handleUserPreference = () => {
    // Implement user preference action
  };

  const handleLogout = () => {
    // Implement logout action
  };

  return (
    <>
      {getLocalStorageItem(LOCAL_STORAGE.AUTH_TOKEN) === null ? (
        <Login />
      ) : (
        <main className="main-page-area">
          <Header onUserPreference={handleUserPreference} onLogout={handleLogout} />
          <div className="main-view-area d-flex align-items-start justify-content-start position-relative">
            <Sidebar
              currentDashboardRoute={currentDashboardRoute}
              setCurrentDashboardRoute={setCurrentDashboardRoute}
            />
            <div className="main-content">
              <Outlet />
            </div>
          </div>
        </main>
      )}
    </>
  );
};
