import React from 'react';
import { EditIcon } from '../../../assets/icons';

export const ActionBox2 = ({
  move_id,
  demand_quantity,
  lotno,
  setPopUp,
  setPopUpData,
  producing_quantity,
}) => {
  return (
    <div className="td-num">
      <EditIcon
        onClick={(e) => {
          e.stopPropagation();
          setPopUp(true);
          setPopUpData({ lotno, move_id, demand_quantity, producing_quantity });
        }}
      />
    </div>
  );
};
