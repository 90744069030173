import { API, METHODS } from '../constants';
import { fetchData } from '../utils';

export const vendorDispatch = async ({ data = {} }) => {
  const url = API.DISPATCH;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const vendorModalDispatch = async ({ data = {} }) => {
  const url = API.MODAL_DISPATCH;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const vendorModalSubmitDispatch = async ({ data = {} }) => {
  const url = API.MODAL_SUBMIT_DISPATCH;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const vendorCreateDispatch = async ({ data = {} }) => {
  const url = API.CREATE_DISPATCH;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const vendorDeleteDispatch = async ({ data = {} }) => {
  const url = API.DELETE_DISPATCH;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const vendorUpdateContainerNo = async ({ data = {} }) => {
  const url = API.UPDATE_CONTAINER_NO;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const vendorUpdateWarehouse = async ({ data = {} }) => {
  const url = API.UPDATE_WAREHOUSE;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const vendorSubmitDispatch = async ({ data = {} }) => {
  const url = API.SUBMIT_DISPATCH;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const getPreScannedPackages = async ({ data = {} }) => {
  const url = API.SHIPMENT;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const addPackageToDispatch = async ({ data = {} }) => {
  const url = API.READY_FOR_DISPATCH;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const markDispatched = async ({ data = {} }) => {
  const url = API.DISPATCH_STATUS;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
