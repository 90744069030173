import React, { useEffect, useState } from 'react';
import { DropDown, SearchBar, TitleBar } from '../../common';
import {
  BUTTON,
  DISPLAY_TEXTS,
  PKJ_STATUS,
  POPUP_MSG,
  POPUP_TYPE,
  ROUTES,
  TITLES,
} from '../../../constants';
import { Button, Input, PopUpBox } from '../../shared';
import { useLocation, useNavigate } from 'react-router-dom';
import { StatusBox } from '../manufacturing/clothManufacturing/StatusBox';
import { Scanner } from '../../common/Scanner';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import {
  vendorUpdateWarehouse,
  vendorDeleteDispatch,
  vendorSubmitDispatch,
  getPreScannedPackages,
  addPackageToDispatch,
  vendorUpdateContainerNo,
} from '../../../service';
import { useDispatch } from 'react-redux';
import { shipmentDataCompiler } from '../../../utils/dataCompiler/dispatchDataCompiler';
import { BlpTilesDispatch } from '../../common/BlpTilesDispatch';

export const CreateDispatch = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [tilesToDisplay, setTilesToDisplay] = useState([]);
  const [scanData, setScanData] = useState('');
  const [scanning, setScanning] = useState(false);
  const [reload, setReload] = useState(false);
  const [validate, setValidate] = useState(false);
  const [preScannedPackages, setPreScannedPackages] = useState([]);
  const [isCamScannerOpen, setIsCamScannerOpen] = useState(true);
  const [update, setUpdate] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [shipmentData, setShipmentData] = useState({
    warehouse_id: '',
    warehouse_name: '',
    container_id: '',
    warehouse_options: [],
  });
  const isCameraScan = localStorage.getItem('isCameraScan');
  const isCameraScanBool = isCameraScan === 'true';

  // Handler for container number input change

  useEffect(() => {
    if (isCameraScanBool && !isCamScannerOpen) setScanning(false);
  }, [isCamScannerOpen, isCameraScanBool]);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getAlreadyScannedPackages();
  }, []);

  const getAlreadyScannedPackages = useDebounce((searchData) => {
    getPreScannedPackages({ data: { id: state?.id, ...searchData } })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          setPreScannedPackages(shipmentDataCompiler(response.data[0])),
            setShipmentData({
              warehouse_id: response.data[0]?.warehouse_id?.id,
              warehouse_name: response.data[0]?.warehouse_id?.name,
              container_id: response.data[0]?.container_id || '',
              warehouse_options: response.data[0]?.warehouse_ids,
            });
        } else {
          setPreScannedPackages(response.message);
        }
        setReload(false);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    let searchData = {};
    if (search && searchTerm) {
      searchData = {
        'package_level_ids.package_id.name': searchTerm,
        'package_level_ids.package_id.quant_ids.product_id.default_code': searchTerm,
        'package_level_ids.package_id.quant_ids.lot_id.name': searchTerm,
         search: 'True',
        // need to be added after package addition start
      };
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getAlreadyScannedPackages(searchData);
    } else if (!searchTerm && !Array.isArray(preScannedPackages)) {
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getAlreadyScannedPackages(searchData);
    }
  }, [search, searchTerm]);

  useEffect(() => {
    if (!searchTerm) {
      dispatch(setLoaderVisibility(true));
      getAlreadyScannedPackages();
    }
  }, [searchTerm]);

  useEffect(() => {
    if (Array.isArray(preScannedPackages)) {
      const initialTiles = preScannedPackages.map((tile) => {
        const isUniformSKU = tile.SKU_Details.every((sku) => sku.SKU === tile.SKU_Details[0].SKU);
        const tileClass = isUniformSKU ? 'blue-shade' : 'pink-shade';
        return { ...tile, tileClass };
      });
      setTilesToDisplay(initialTiles);
    }
  }, [preScannedPackages]);

  useEffect(() => {
    if (scanData) {
      dispatch(setLoaderVisibility(true));
      addPackageToDispatch({
        data: { dispatch_id: state?.id, package_name: scanData, type: 'add' },
      }).then((response) => {
        popup(POPUP_TYPE.SUCCESS, response.message);
        getAlreadyScannedPackages();
        setScanData('');
      });
    }
  }, [scanData]);

  useEffect(() => {
    if (reload) {
      dispatch(setLoaderVisibility(true));
      getAlreadyScannedPackages();
      setReload(!reload);
    }
  }, [reload]);

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.DISPATCH, route: ROUTES.DISPATCH },
          { text: TITLES.CREATE_DISPATCH, route: ROUTES.NULL },
        ]}
      />
      <ShipmentDetailsBox
        state={state}
        tilesToDisplay={tilesToDisplay}
        setUpdate={setUpdate}
        shipmentData={shipmentData}
        setScanning={setScanning}
        scanning={scanning}
        setIsCamScannerOpen={setIsCamScannerOpen}
        isCameraScanBool={isCameraScanBool}
        setScanData={setScanData}
        preScannedPackages={preScannedPackages}
        setValidate={setValidate}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        setSearch={setSearch}
      />
      <div className="bg-white p-3 pt-0 package-titles-info border">
        <div className="row custom-row">
          {Array.isArray(preScannedPackages)
            ? tilesToDisplay.map((tile, index) => (
                <BlpTilesDispatch
                  key={index}
                  tile={tile}
                  dispatch_id={state?.id}
                  dispatch_status={state?.dispatch_status}
                  showLoader={scanning}
                  setReload={setReload}
                />
              ))
            : typeof preScannedPackages === 'string' && (
                <div>
                  <h4 className="text-center px-1 py-5">{'Package not found'}</h4>
                </div>
              )}
        </div>
      </div>

      {validate && (
        <PopUpBox openPopUpBox={validate}>
          <DispatchSubmitValidate
            setOpenPopUpBox={setValidate}
            id={state?.id}
            containerNo={shipmentData.container_id}
          />
        </PopUpBox>
      )}
      {update && (
        <PopUpBox openPopUpBox={update}>
          <AddDetails
            setReload={setReload}
            setOpenPopUpBox={setUpdate}
            state={state}
            shipmentData={shipmentData}
          />
        </PopUpBox>
      )}
    </>
  );
};

export const AddDetails = ({ setReload, shipmentData, state, setOpenPopUpBox }) => {
  const dispatch = useDispatch();
  const [containerNo, setContainerNo] = useState(shipmentData.container_id);
  const [warehouseID, setWareHouseId] = useState(shipmentData.warehouse_name);
  const handleUpdate = () => {
    if (shipmentData.container_id !== containerNo) {
      handleContainerUpdate();
    }
    if (shipmentData.warehouse_name !== warehouseID) {
      handleLocationUpdate();
    }
  };
  const handleLocationUpdate = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    vendorUpdateWarehouse({
      data: {
        dispatch_id: state?.id,
        warehouse_id: shipmentData.warehouse_options.filter((opt) => opt.name === warehouseID)[0]
          .id,
      },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        response.data.length
          ? popup(POPUP_TYPE.SUCCESS, response.message)
          : popup(POPUP_TYPE.ERR, response.message);
        setOpenPopUpBox(false);
        setReload(true);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handleContainerUpdate = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    vendorUpdateContainerNo({
      data: { dispatch_id: [state?.id], container_id: containerNo },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        setOpenPopUpBox(false);
        setReload(true);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handleContainerNoChange = (e) => {
    setContainerNo(e.target.value);
  };
  return (
    <div className="m-2">
      <h5 className="mb-3 d-flex justify-content-center">Update Dispatch Details </h5>
      <hr></hr>
      <div className="d-flex ps-4  gap-4">
        <div className="d-flex flex-column gap-3">
          <label className="container-label">Container No</label>
          <label className="container-label">Warehouse</label>
        </div>
        <div className="d-flex flex-column  align-items-start gap-3 ">
          <Input
            type="text"
            placeholder={shipmentData.container_id || 'Container No'}
            className="d-flex me-2 search-placeholder align-items-center justify-content-center container-input"
            value={containerNo}
            onChange={(e) => {
              handleContainerNoChange(e);
            }}
          />
          <DropDown
            className={''}
            dropdownMenu={shipmentData.warehouse_options
              .filter((opt) => opt.name !== shipmentData.warehouse_name)
              .map((opt) => opt.name)}
            value={warehouseID}
            setValue={setWareHouseId}
          />
        </div>
      </div>
      <hr />

      <div className=" d-flex justify-content-end gap-3">
        <Button
          className={`search-btn ${shipmentData.container_id === containerNo && shipmentData.warehouse_name === warehouseID ? 'disabled' : ''} d-flex align-items-center justify-content-center px-3`}
          clickHandler={handleUpdate}
          disabled={
            shipmentData.container_id === containerNo && shipmentData.warehouse_name === warehouseID
          }>
          {BUTTON.SAVE}
        </Button>
        <Button
          className={`search-btn d-flex align-items-center justify-content-center px-3`}
          clickHandler={() => {
            setOpenPopUpBox(false);
          }}>
          {BUTTON.CANCEL}
        </Button>
      </div>
    </div>
  );
};
export const ShipmentDetailsBox = ({
  state,
  setScanData,
  scanning,
  setScanning,
  setIsCamScannerOpen,
  isCameraScanBool,
  tilesToDisplay,
  setUpdate,
  shipmentData,
  isCamScannerOpen,
  preScannedPackages,
  setValidate,
  setSearchTerm,
  searchTerm,
  setSearch,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const startScanning = () => {
    setScanning((prev) => !prev);
    setIsCamScannerOpen(isCameraScanBool);
  };
  const deleteDispatch = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    vendorDeleteDispatch({ data: { dispatch_id: state?.id } })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        navigate(ROUTES.DISPATCH);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);
  return (
    <div className="bg-white p-3 align-items-center package-top-info border-def-up border-def-down">
      <div className="d-flex align-items-center justify-content-between">
        <div className="align-items-center justify-content-between mb-3 top-header-set">
          <h4 className="mb-0">
            {DISPLAY_TEXTS.CREATE_DISPATCH[0]}
            <span>{state?.name}</span>
          </h4>
        </div>
        <StatusBox status={PKJ_STATUS} />
      </div>
      <div className="d-flex align-items-center justify-content-between mb-3 top-header-set">
        <div className="">
          <div className="d-flex align-items-center justify-content-start ">
            <label className="container-label">
              Container No : <span>{shipmentData.container_id}</span>{' '}
            </label>
          </div>
          <div className="d-flex align-items-center justify-content-start ">
            <label className="container-label">
              Warehouse : <span>{shipmentData.warehouse_name}</span>
            </label>
          </div>
          {preScannedPackages.length > 0 && state?.state==='mohak' && (
            <SearchBar
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              setSearch={setSearch}
            />
          )}
        </div>
        <div className="d-flex flex-column justify-content-end align-items-end">
          <div className="d-flex justify-content-end align-items-center">
            <h4 className="me-3 mb-4">{DISPLAY_TEXTS.CREATE_DISPATCH[1]}</h4>
            <div className="number-count mb-4 d-flex align-items-center justify-content-center">
              {tilesToDisplay.length}
            </div>
          </div>
          {state?.dispatch_status==='draft' && <div className="d-flex justify-content-end align-items-center">
            {scanning && (
              <Scanner
                setScanData={setScanData}
                isCamScannerOpen={isCamScannerOpen}
                setIsCamScannerOpen={setIsCamScannerOpen}
              />
            )}
            <Button
              clickHandler={startScanning}
              className="white-btn d-flex align-items-center justify-content-center px-4 mx-2">
              {scanning ? BUTTON.STOP_SCAN : BUTTON.SCAN_BOX}
            </Button>
          </div>}
        </div>
      </div>

      {state?.dispatch_status==='draft' &&<div className="d-flex justify-content-between">
        <Button
          clickHandler={deleteDispatch}
          className="blue-btn d-flex align-items-center justify-content-center px-4 mx-2">
          {BUTTON.CANCEL_DISPATCH}
        </Button>
        <div className="d-flex justify-content-end gap-3">
          <Button
            className={`blue-btn  d-flex align-items-center justify-content-center px-3`}
            clickHandler={() => {
              setUpdate(true);
            }}>
            {BUTTON.UPDATE}
          </Button>
          <Button
            className={`blue-btn d-flex ${preScannedPackages.length + tilesToDisplay.length === 0 ? 'disabled' : ''} align-items-center justify-content-center px-4`}
            clickHandler={() => setValidate(true)}
            disabled={preScannedPackages.length + tilesToDisplay.length === 0}>
            {BUTTON.SUBMIT}
          </Button>
        </div>
      </div>}
    </div>
  );
};

const DispatchSubmitValidate = ({ setOpenPopUpBox, id, containerNo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closePopUp = () => {
    setOpenPopUpBox(false);
  };

  const handleSubmitDispatch = () => {
    setOpenPopUpBox(false);
    dispatch(setLoaderVisibility(true));
    vendorSubmitDispatch({
      data: { dispatch_id: id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        navigate(ROUTES.DISPATCH);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const handleSaveAsDraft = () => {
    popup('success', 'Dispatch Saved as Draft');
    navigate(ROUTES.DISPATCH);
  };

  return (
    <>
      <div className="d-flex m-1">
        <div className="w-25 modal-body py-0 d-flex justify-content-center align-items-center">
          <span className="d-flex justify-content-center align-items-center bg-info badge">!</span>
        </div>
        <div className="w-75 modal-body py-0">
          <h4 className="text-center text-special mb-3">
            <u>NOTE</u>
          </h4>
          <p className="text-center text-special placeholder-color">
            {POPUP_MSG.SUBMIT_DISPATCH_WARNING}
          </p>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4 modal-btn custom-button">
        <div className="d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleSubmitDispatch}
            className={`border-0 m-1 ${containerNo === '' || containerNo === false || containerNo === 'N/A' ? 'disabled' : ''} blue-btn d-flex align-items-center justify-content-center py-2 px-3`}
            disabled={containerNo === '' || containerNo === false || containerNo === 'N/A'}>
            {BUTTON.SUBMIT}
          </Button>
          <Button
            clickHandler={handleSaveAsDraft}
            className="border-0 m-1 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.SAVE_AS_DRAFT}
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 m-1 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};
