import React, { useState, useEffect } from 'react';
import { Button, Input } from '../../../shared';
import { DropDownInput } from '../../../common';
import { useDispatch } from 'react-redux';
import { vendorModalDispatch, vendorModalSubmitDispatch } from '../../../../service';
import { useDebounce } from '../../../../hooks';
import { setLoaderVisibility } from '../../../../redux';
import { getLocalStorageItem, popup } from '../../../../utils';
import { BUTTON, DISPLAY_TEXTS, LOCAL_STORAGE } from '../../../../constants';

export const FabricProductionModal = ({ closePopUp, modalData, setReload }) => {
  const [products, setProducts]= useState([]);
  const [productsList, setProductsList] = useState([]);
  const [rows, setRows] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [producingQuantity, setProducingQuantity] = useState(
    modalData.demand_quantity !== modalData.producing_quantity
      ? (modalData.demand_quantity - modalData.producing_quantity).toFixed(2).toString()
      : modalData.demand_quantity.toFixed(2).toString(),
  );
  const dispatch = useDispatch();

  const getProductsList = useDebounce(() => {
    vendorModalDispatch({
      data: { move_id: modalData.move_id },
    })
      .then((response) => {
        if (response.message.toLowerCase().includes('not')) popup('error', response.message);
        else setProductsList(response.data);
        dispatch(setLoaderVisibility(false));
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getProductsList();
  }, []);

  useEffect(() => {
    if (productsList.length > 0) {
      const data = [
        {
          move_id: productsList[0].id,
          name: productsList[0]?.display_name?.toString(),
          defaultLotNo: productsList[0]?.selected_lot?.[0]?.name,
          defaultLotId: productsList[0]?.selected_lot?.[0]?.id,
          tracking: productsList[0]?.tracking,
          lotNumbers:
            productsList[0]?.lots?.length > 0 ? productsList[0].lots.map((lot) => lot.name) : [],
          unitMultiplier: productsList[0].quantity,
        },
      ];
      const initialRows = data.map((product) => ({
        move_id: product.move_id,
        product: product.name,
        dropdown1: product.defaultLotNo,
        defaultLotId: product.defaultLotId,
        producingQuantity: '',
        calculatedQuantity: '',
        unitMultiplier: product.unitMultiplier,
      }));
      setRows(initialRows);
      setProducts(data);
    }
  }, [productsList]);

  useEffect(() => {
    const allFilled = rows.every((row) => row.calculatedQuantity !== '');
    setIsSubmitDisabled(!allFilled);
  }, [rows]);

  const handleChange = (field, value, rowIndex) => {
    const newRows = [...rows];
    if (field === 'producingQuantity') {
      setProducingQuantity(value);
      setIsSaveDisabled(value <= 0);
    } else if (field === 'dropdown1') {
      newRows[rowIndex][field] = value;
    }
    setRows(newRows);
  };

  const handleSave = () => {
    const newRows = rows.map((row) => ({
      ...row,
      producingQuantity,
      calculatedQuantity: (producingQuantity * row.unitMultiplier).toFixed(4),
      lot_id: row.defaultLotId,
    }));
    setRows(newRows);
  };

  const handleSubmit = useDebounce(() => {
    const data = rows.map((row) => ({
      move_line_id: row.move_id,
      lot_id:
        getLocalStorageItem(LOCAL_STORAGE.VENDOR_TYPE) === 'fabric' ? false : parseInt(row.lot_id),
    }));
    const finalObject = {
      move_id: modalData.move_id,
      producing_qty: parseFloat(producingQuantity),
      data: data,
    };
    vendorModalSubmitDispatch({
      data: finalObject,
    })
      .then((response) => {
        if (response.message.includes('more')) popup('error', response.message);
        else popup('success', response.message);
        dispatch(setLoaderVisibility(false));
        setReload(true);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
    closePopUp(false);
  }, 1000);

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title w-100" id="fabricDispatchModalLabel">
          {DISPLAY_TEXTS.FABRIC_DISPATCH[0]}
        </h5>
      </div>
      <div className="modal-body">
        <div className="w-100 editing-container">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="edit-container py-3 px-lg-4 px-4 table-new-div bg-white data-box w-100 h-100">
              {/* Detail Box */}
              <div className="detail-box-popup mb-3">
                <div className="row mb-1">
                  <div className="col-12">
                    <strong>{DISPLAY_TEXTS.FABRIC_DISPATCH[1]}</strong> {modalData.lotno}
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-12">
                    <strong>{DISPLAY_TEXTS.FABRIC_DISPATCH[2]}</strong>{' '}
                    {modalData.demand_quantity.toFixed(2)}
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-12">
                    <strong>{DISPLAY_TEXTS.FABRIC_DISPATCH[4]}</strong>{' '}
                    {modalData.producing_quantity.toFixed(2)}
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-12">
                    <div className="d-flex align-items-center">
                      <strong>{DISPLAY_TEXTS.FABRIC_DISPATCH[3]}</strong>
                      <Input
                        className="prd-qty-input"
                        type="number"
                        value={producingQuantity}
                        onChange={(e) => handleChange('producingQuantity', e.target.value)}
                      />
                      <span className="m-1">
                        {' '}
                        /{' '}
                        {modalData.demand_quantity !== modalData.producing_quantity
                          ? (modalData.demand_quantity - modalData.producing_quantity).toFixed(2)
                          : modalData.demand_quantity.toFixed(2)}
                      </span>
                      <Button
                        className={`m-1 border-0 blue-btn align-items-center justify-content-center py-2 px-3 ${isSaveDisabled ? 'disabled' : ''}`}
                        clickHandler={handleSave}
                        disabled={isSaveDisabled}>
                        {BUTTON.SAVE}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="w-100 table px-1">
                  <table className="w-100">
                    <thead>
                      <tr>
                        {DISPLAY_TEXTS.FABRIC_DISPATCH_MODAL.map((column, index) =>
                          products?.[0]?.tracking !== 'lot' &&
                          column === 'Lot No.' &&
                          getLocalStorageItem(LOCAL_STORAGE.VENDOR_TYPE) !== 'dyeing' ? null : (
                            <th
                              className={`text-muted ${index === 0 ? 'first-col' : 'last-col'}`}
                              key={index}>
                              {column}
                            </th>
                          ),
                        )}
                      </tr>
                    </thead>
                    <tbody className="inner-table-tbody-input">
                      {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td>
                            <span className="display-row-text">{row.product}</span>
                          </td>
                          {products?.[0]?.tracking !== 'lot' &&
                          getLocalStorageItem(LOCAL_STORAGE.VENDOR_TYPE) !== 'dyeing' ? null : (
                            <td className="custom-padding ">
                              <DropDownInput
                                dropdownMenu={productsList[rowIndex]?.lots.map((lot) => lot.name)}
                                value={row.dropdown1}
                                onChange={(value) => handleChange('dropdown1', value, rowIndex)}
                              />
                            </td>
                          )}
                          <td className="custom-padding">
                            <span className="display-row-text">{row.calculatedQuantity}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          className={`border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3 ${isSubmitDisabled ? 'disabled' : ''}`}
          clickHandler={handleSubmit}
          disabled={isSubmitDisabled}>
          {BUTTON.SUBMIT}
        </Button>
        <Button
          className="border-0 grey-btn d-flex align-items-center justify-content-center py-2 px-3"
          clickHandler={() => closePopUp(false)}>
          {BUTTON.CLOSE}
        </Button>
      </div>
    </>
  );
};
