import  React from "react";
const Checked = (props) => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="checkboxIconTitle"
    stroke="#ffffff"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="none"
    color="#ffffff"
    {...props}
  >
    <title id="checkboxIconTitle">{"Checkbox (selected)"}</title>
    <rect
      x={21}
      y={3}
      width={18}
      height={18}
      rx={1}
      transform="rotate(90 21 3)"
    />
    <path d="M6.66666 12.6667L9.99999 16L17.3333 8.66669" />
  </svg>
);
export default Checked;