import React, { useState, useRef, useCallback, useEffect } from 'react';
import { TableHead, TableBody } from '../shared';
import { useIntersectionObserver } from '../../utils/useIntersectionObserver';
import { sortingByName, sortingByValue } from '../../utils';
import { SORTING } from '../../constants';

export const Table = ({
  columns,
  initialData = [],
  PAGE_SIZE = 10,
  colFilter,
  to,
  compiler,
  popUp,
  setPopUp,
  setPopUpData,
  setReload,
  func,
  className = 'table-1',
}) => {
  // State to manage selected columns
  
  // State to manage sorting
  const [sortInfo, setSortInfo] = useState({
    sortType: SORTING.DEFAULT,
    index: 0,
    valueType: '',
    value: 0,
  });

  // State to manage data and pagination
  const [data, setData] = useState([]); // Holds the currently loaded data
  const [pageNumber, setPageNumber] = useState(1); // Tracks the current page
  const [hasMore, setHasMore] = useState(false); // Tracks if more data can be loaded
  const [isLoading, setIsLoading] = useState(false); // Tracks loading state
  const tableEndRef = useRef(null); // Reference for infinite scrolling

  // Columns filtered based on user selection

  // Reset data and pagination states when `initialData` changes
  useEffect(() => {
    setData(initialData.slice(0, PAGE_SIZE)); // Load the first page of data
    setPageNumber(1); // Reset to the first page
    setHasMore(initialData.length > PAGE_SIZE); // Check if there's more data to load
  }, [initialData, PAGE_SIZE]);

  // Function to load more data
  const loadMoreData = useCallback(() => {
    if (!hasMore || isLoading) return; // Prevent multiple simultaneous calls

    setIsLoading(true);

    // Simulate data loading
    setTimeout(() => {
      const start = pageNumber * PAGE_SIZE;
      const end = (pageNumber + 1) * PAGE_SIZE;

      const newData = initialData.slice(start, end);
      setData((prevData) => [...prevData, ...newData]);

      // Update `hasMore` and `pageNumber`
      setHasMore(initialData.length > end);
      setPageNumber((prevPageNumber) => prevPageNumber + 1);

      setIsLoading(false);
    }, 300); // Simulated delay
  }, [pageNumber, initialData, PAGE_SIZE, hasMore, isLoading]);

  // Intersection Observer to trigger `loadMoreData` when the table end is visible
  useIntersectionObserver({
    target: tableEndRef,
    onIntersect: loadMoreData,
    enabled: hasMore && !isLoading,
  });

  // Sorting function (optional, for table header interaction)
  const handleSort = useCallback(
    ({ sortInfo, data }) => {
      const { sortType, index, valueType } = sortInfo;
      let result = [];
      if (valueType === 'String') {
        const dataFieldName = columns[index]?.dataField[0];
        if (sortType === SORTING.ASCENDING) {
          result = sortingByName({ data: data, sortBy: dataFieldName, order: 1 });
        } else if (sortType === SORTING.DESCENDING) {
          result = sortingByName({ data: data, sortBy: dataFieldName, order: 0 });
        }
      } else {
        const dataFieldName = columns[index]?.dataField;
        if (sortType === SORTING.ASCENDING) {
          result = sortingByValue({ data: data, sortBy: dataFieldName, order: 0 });
        } else if (sortType === SORTING.DESCENDING) {
          result = sortingByValue({ data: data, sortBy: dataFieldName, order: 1 });
        }
      }
      return result;
    },
    [columns],
  );

  return (
    <div className={`table-container ${className} position-relative mb-3`}>
      <table className="w-100">
        <TableHead
          columns={columns}
          colFilter={colFilter}
          sortInfo={sortInfo}
          setSortInfo={setSortInfo}
        />
        <TableBody
          columns={columns}
          data={data}
          colFilter={colFilter}
          to={to !== '' ? to : undefined}
          compiler={compiler}
          sortInfo={sortInfo}
          popUp={popUp}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
          setReload={setReload}
          handleSort={handleSort}
          func={func}
        />
      </table>
      <div ref={tableEndRef} className="border intersection-observer-trigger" />
      {isLoading && <div>Loading more data...</div>}
    </div>
  );
};
