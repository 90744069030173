import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '../shared';

// import {formatDateToString} from '../../utils/misc';

export const DatePickerBox = ({ value, setValue, clickHandler, showSave, btnName }) => {
  const [disable, setDisable] = useState(false);

  const dateChangeHandler = (e) => {
    setValue(e);
  };
  const handleFocus = (e) => {
    setDisable(false);
    e?.preventDefault();
    e?.target.blur(); // Blurs the input to hide the keyboard
  };
  useEffect(() => {

    setDisable(false);
  }, []);

  return (
    <>
      <DatePicker
        selected={value}
        onChange={dateChangeHandler}
        className="bill-date-picker "
        dateFormat="yyyy-MM-dd"
        showYearDropdown
        // value={value}
        showMonthDropdown
        scrollableYearDropdown
        readOnly={disable}
        placeholderText="YYYY-MM-DD"
        onFocus={handleFocus}
        onKeyDown={(e) => e.preventDefault()}
        onBlur={() => setDisable(false)}
        onCalendarClose={() => setDisable(true)}
        onCalenderOpen={() => setDisable(true)}
      />
      {showSave === 'hide' || (
        <Button
          className={`border-0 blue-btn d-flex align-items-center ${!showSave ? 'disabled' : ''} justify-content-center py-2 px-3`}
          clickHandler={clickHandler}
          disabled={!showSave}>
          {btnName}
        </Button>
      )}
    </>
  );
};

export default DatePicker;
