import React from 'react';
import { EditIcon, Separator } from '../../../../assets/icons';

export const StatusBar = ({ lot, setOpenPopBox }) => {
  const statusTab = [
    {
      key: 'Lot No',
      value: lot?.lot_no,
    },
    { key: 'SKU', value: lot?.SKU || 'N/A', edit: false },
    { key: 'Order Unit', value: lot?.total_qty, edit: false },
    { key: 'Ready Unit', value: lot?.ready_qty, edit: false },
    { key: 'Priority', value: lot?.priority, edit: lot.total_qty > lot.ready_qty ? true : false },
  ];
  return (
    <div className="w-100 info-header d-flex align-items-center justify-content-start flex-wrap">
      {statusTab.map((tab) =>
        tab.edit ? (
          <div
            onClick={() => setOpenPopBox(true)}
            key={tab.key}
            className="bg-white info-txt-box d-flex align-items-center justify-content-center">
            <span>{tab.key}</span>
            <Separator />
            <span>{tab.value}</span>
            <EditIcon />
          </div>
        ) : (
          <div
            key={tab.key}
            className="bg-white info-txt-box d-flex align-items-center justify-content-center">
            <span>{tab.key}</span>
            <Separator />
            <span>{tab.value}</span>
          </div>
        ),
      )}
    </div>
  );
};
