import { API,METHODS  } from '../constants';
import { fetchData } from '../utils';

export const vendorPurchase = async ({ data = {} }) => {
  const url = API.PURCHASE;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
 return response

  // return {
  //   message: 'Purchase orders found.',
  //   data: [
  //     {
  //       id: 1838,
  //       name: 'P01838',
  //       partner_ref: false,
  //       date_approve: '2024-11-05 10:52:53',
  //       effective_date: '2024-11-05 11:22:13',
  //       create_date: '2024-11-05 10:52:49',
  //       receipt_status: 'full',
  //       invoice_status: 'to invoice',
  //       state: 'purchase',
  //       date_planned: '2024-11-06 10:52:49',
  //       origin: false,
  //       lot_name: '00558',
  //       amount_total: 1150.0,
  //       partner_id: {
  //         id: 22,
  //         name: 'ESUN',
  //       },
  //       picking_type_id: {
  //         id: 1,
  //         name: 'Receipts',
  //       },
  //       currency_id: {
  //         id: 1,
  //         name: 'USD',
  //       },
  //       order_line: [
  //         {
  //           id: 2847,
  //           name: '[ACS10XL] Active S/S (Red, XL)',
  //           future_lot: '00558',
  //           no_of_rolls: 0,
  //           manufacture_priority: 'low_priority',
  //           product_qty: 100.0,
  //           qty_received: 80.0,
  //           qty_invoiced: 0.0,
  //           price_unit: 10.0,
  //           product_id: {
  //             id: 75,
  //             name: 'Active S/S',
  //             tracking: 'lot',
  //             default_code: 'ACS10XL',
  //             dyeing_type: 'non_dyeing',
  //             case_pack_quantity: 40.0,
  //             display_name: '[ACS10XL] Active S/S (Red, XL)',
  //           },
  //           product_uom: {
  //             id: 1,
  //             name: 'Units',
  //           },
  //           product_packaging_id: false,
  //           taxes_id: [
  //             {
  //               id: 2,
  //               name: '15%',
  //             },
  //           ],
  //         }, {
  //           id: 2847,
  //           name: '[ACS10XL] Active S/S (Red, XL)',
  //           future_lot: '00558',
  //           no_of_rolls: 0,
  //           manufacture_priority: 'low_priority',
  //           product_qty: 100.0,
  //           qty_received: 100.0,
  //           qty_invoiced: 0.0,
  //           price_unit: 10.0,
  //           product_id: {
  //             id: 75,
  //             name: 'Active S/S',
  //             tracking: 'lot',
  //             default_code: 'ACS10XL',
  //             dyeing_type: 'non_dyeing',
  //             case_pack_quantity: 40.0,
  //             display_name: '[ACS10XL] Active S/S (Red, XL)',
  //           },
  //           product_uom: {
  //             id: 1,
  //             name: 'Units',
  //           },
  //           product_packaging_id: false,
  //           taxes_id: [
  //             {
  //               id: 2,
  //               name: '15%',
  //             },
  //           ],
  //         }, {
  //           id: 2847,
  //           name: '[ACS10XL] Active S/S (Red, XL)',
  //           future_lot: '00558',
  //           no_of_rolls: 0,
  //           manufacture_priority: 'low_priority',
  //           product_qty: 100.0,
  //           qty_received: 70.0,
  //           qty_invoiced: 0.0,
  //           price_unit: 10.0,
  //           product_id: {
  //             id: 75,
  //             name: 'Active S/S',
  //             tracking: 'lot',
  //             default_code: 'ACS10XL',
  //             dyeing_type: 'non_dyeing',
  //             case_pack_quantity: 40.0,
  //             display_name: '[ACS10XL] Active S/S (Red, XL)',
  //           },
  //           product_uom: {
  //             id: 1,
  //             name: 'Units',
  //           },
  //           product_packaging_id: false,
  //           taxes_id: [
  //             {
  //               id: 2,
  //               name: '15%',
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // };
};
