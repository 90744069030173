import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DetailBox, Table, TitleBar, Total } from '../../common';
import { BILLS_DETAILS_COLUMN, TITLES, ROUTES, POPUP_TYPE, DATA_FIELD } from '../../../constants';
import {
  billDetailsCompiler,
  billsDetailBox,
  popup,
  dateFormatter,
  getCurrentDateInput,
  reducedCalc,
} from '../../../utils';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { updateBill } from '../../../service';

export const BillsDetails = () => {
  const { state } = useLocation(location.state);
  const [value, setValue] = useState(state?.invoice_date || getCurrentDateInput());
  const [showSave, setShowSave] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const qty = reducedCalc(state?.invoice_line_ids, DATA_FIELD.QTY);

  const handleDateChange = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    updateBill({
      data: {
        bill_id: state?.id,
        bill_date: dateFormatter(value),
      },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        setShowSave(false);
        navigate(ROUTES.BILLS);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    if(!state) navigate(ROUTES.BILLS)

    if (value !== state?.invoice_date) setShowSave(true);
  }, [value]);
  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.BILLS, route: ROUTES.BILLS },
          { text: state?.name, route: ROUTES.NULL },
        ]}
      />
      <div className="scroll-div">
        <DetailBox
          colCount={1}
          colData={state}
          dataCompiler={billsDetailBox}
          value={value}
          setValue={setValue}
          clickHandler={handleDateChange}
          showSave={showSave}
        />
        <Table
          columns={BILLS_DETAILS_COLUMN}
          initialData={state?.invoice_line_ids}
          PAGE_SIZE={4}
          colFilter={false}
          compiler={{ use: true, tool: billDetailsCompiler }}
        />
        <Total
          qty={qty}
          total={state?.amount_total}
          uom={state?.invoice_line_ids[0]?.product_uom_id?.name}
          currency_code={state?.currency_id?.name}
        />
      </div>
    </>
  );
};
