import React from 'react';
import { Tiles } from './Tiles.js';
import { TILES, ROUTES, LOCAL_STORAGE, APPS } from '../../../constants';
import { D1, D2, D3, D4, D5, D6 } from '../../../assets/icons/index.js';
import { getLocalStorageItem } from '../../../utils/localStorage.js';
import D7 from '../../../assets/icons/D7.js';

export const DashboardContent = () => {
  const allowedApps = [...getLocalStorageItem(LOCAL_STORAGE.ALLOWED_APP)];
  return (
    <>
      <div className="dashboard-block col-10 mx-auto d-flex align-items-center justify-content-center h-100">
        <div className="row db-container w-100">
          {allowedApps.includes(APPS.PURCHASE) && (
            <Tiles tileName={TILES.PURCHASE_ORDER} Icon={D1} route={ROUTES.PURCHASE_ORDER} />
          )}
          {allowedApps.includes(APPS.BILL) && (
            <Tiles tileName={TILES.BILLS} Icon={D2} route={ROUTES.BILLS} />
          )}

          {allowedApps.includes(APPS.RECEIPT) && (
            <Tiles tileName={TILES.RECEIPT} Icon={D3} route={ROUTES.RECEIPT} />
          )}
          {allowedApps.includes(APPS.MFG) && (
            <Tiles tileName={TILES.MANUFACTURING} Icon={D4} route={ROUTES.MANUFACTURING} />
          )}
          {allowedApps.includes(APPS.PACK) && (
            <Tiles tileName={TILES.PACKAGE} Icon={D5} route={ROUTES.PACKAGE} />
          )}
          {allowedApps.includes(APPS.DISPATCH) && (
            <Tiles tileName={TILES.DISPATCH} Icon={D6} route={ROUTES.DISPATCH} />
          )}
          {allowedApps.includes(APPS.REPORT_NAME) && (
            <Tiles tileName={TILES.REPORTS} Icon={D7} route={ROUTES.REPORTS} />
          )}
        </div>
      </div>
    </>
  );
};
