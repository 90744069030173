import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../../redux';
import { useDebounce } from '../../../../hooks';
import { StatusBar } from './StatusBar';
import { DropDown, TitleBar } from '../../../common';
import { BatchDetails } from './BatchDetails';
import { BatchEditBox } from './BatchEditBox';
import { TITLES, ROUTES, POPUP_TYPE, DROPDOWN, BUTTON } from '../../../../constants';
import { batchInfo, updatePriority } from '../../../../service/manufacturing';
import { popup, batchDataCompiler } from '../../../../utils';
import { Button, PopUpBox } from '../../../shared';

export const LotInfo = () => {
  const [editBoxState, setEditBoxState] = useState(0);
  const [selectedBatch, setSelectedBatch] = useState('');
  const navigate = useNavigate();
  const { state } = useLocation();
  const [reload, setReload] = useState(true);
  const [lot, setLot] = useState({});
  const [field, setField] = useState('');
  const [openPopUpBox, setOpenPopBox] = useState(false);
  const dispatch = useDispatch();
  // API Call
  const getLotDetails = useDebounce(() => {
    batchInfo({
      data: {
        order_line_id: state?.id,
      },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        Object.keys(response.data).length
          ? setLot(batchDataCompiler(response.data))
          : popup(POPUP_TYPE.ERR, response.message);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
    setReload(false);
  }, 1000);
  useEffect(() => {
    if (!state) navigate(ROUTES.MANUFACTURING);

    if (reload) {
      dispatch(setLoaderVisibility(true));
      getLotDetails();
    }
  }, [reload]);
  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.MANUFACTURING, route: ROUTES.MANUFACTURING },
          { text: lot?.lot_no, route: ROUTES.NULL },
        ]}
      />
      <div className="w-100 bg-white border-def-up">
        <StatusBar lot={lot} setOpenPopBox={setOpenPopBox} />
        <div className="d-flex flex-column ms-2">
          <div className="d-flex">
            <h5 className="mt-1">Product :  </h5>
            <h6 className="ms-3 mt-2">{lot?.lotData?.product_id?.display_name || 'N/A'}</h6>
          </div>
          <div className="d-flex">
            <h5 className="mt-1">
              Finished Product 
            </h5><h6 className="ms-3 mt-2">{lot?.lotData?.dyeing_product_id?.display_name || 'N/A'}</h6>
          </div>
        </div>
        <div className="secondary-scroll-bar">
          <BatchDetails
            setSelectedBatch={setSelectedBatch}
            lot={lot}
            editBoxState={editBoxState}
            setEditBoxState={setEditBoxState}
            setField={setField}
            reload={reload}
            setReload={setReload}
          />
          <BatchEditBox
            selectedBatch={selectedBatch}
            lot={lot}
            setLot={setLot}
            editBoxState={editBoxState}
            setEditBoxState={setEditBoxState}
            field={field}
            setField={setField}
            reload={reload}
            setReload={setReload}
          />
        </div>
        <PopUpBox openPopUpBox={openPopUpBox}>
          <PopUpBody
            setOpenPopBox={setOpenPopBox}
            lot={lot}
            reload={reload}
            setReload={setReload}
          />
        </PopUpBox>
      </div>
    </>
  );
};

const PopUpBody = ({ setOpenPopBox, lot, reload, setReload }) => {
  const dispatch = useDispatch();
  const [priority, setPriority] = useState(lot?.priority || 'Select Priority');
  const handleApiCall = useDebounce(() => {
    let newPriority = '';
    if (priority === 'Priority') newPriority = 'priority';
    else if (priority === 'Make First Order') newPriority = 'make_first_order';
    else if (priority === 'Low Priority') newPriority = 'low_priority';
    updatePriority({
      data: { line_id: lot?.lotData?.id, priority: newPriority },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        setOpenPopBox(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
  }, 1000);
  return (
    <>
      <div className="d-flex m-1">
        <div className="w-75 modal-body py-0">
          <h4 className="text-center text-special mb-3">Change Priority</h4>
        </div>
      </div>
      <DropDown dropdownMenu={DROPDOWN.MFG_SORT_1} value={priority} setValue={setPriority} />
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4 modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.UPDATE}
          </Button>
          <Button
            clickHandler={() => setOpenPopBox(false)}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};

export default PopUpBody;
