import DashboardIcon from './DashboardIcon';
import Purchase from './Purchase';
import Bills from './Bills';
import Receipt from './Receipt';
import Packages from './Packages';
import Reports from './Reports';
import GetStarted from './GetStarted';
import Manufacturing from './Manufacturing';
import Dispatch from './Dispatch';
import D1 from './D1';
import D2 from './D2';
import D3 from './D3';
import D4 from './D4';
import D5 from './D5';
import D6 from './D6';
import D7 from './D7';
import Arrow from './Arrow';
import Search from './Search';
import Reference from './Reference';
import Sort from './Sort';
import Sort1 from './Sort1';
import PaginationArrow from './PaginationArrow';
import DropDownArrow2 from './DropDownArrow2';
import OrangeLabelTag from './OrangeLabelTag';
import RedLabelTag from './RedLabelTag.js';
import GreenLabelTag from './GreenLabelTag';
import BoxIcon from './BoxIcon.js';
import OpenEyeIcon from './OpenEyeIcon.js';
import EyeIcon from './EyeIcon.js';
import Separator from './Separator.js';
import Edit1 from './Edit1.js';
import EditIcon from './EditIcon.js';
import ErrorIcon from './ErrorIcon.js';
import BlueCheckIcon from './BlueCheckIcon.js';
import CheckIcon from './CheckIcon.js';
import DeleteRedIcon from './DeleteRedIcon.js';
import Swap from './Swap.js';
import ASCSortIcon from './ASCSort.js';
import DESCSortIcon from './DESCSortIcon.js';
import Close from './Close.js';
import Checked from './Checked.js';
import UnChecked from './UnChecked.js';

export {
  DashboardIcon,
  Purchase,
  Bills,
  Receipt,
  Packages,
  Reports,
  GetStarted,
  Manufacturing,
  Dispatch,
  D1,
  D2,
  D3,
  D4,
  D5,
  D6,
  D7,
  Arrow,
  Search,
  Reference,
  Sort,
  Sort1,
  PaginationArrow,
  DropDownArrow2,
  OrangeLabelTag,
  RedLabelTag,
  GreenLabelTag,
  BoxIcon,
  OpenEyeIcon,
  EyeIcon,
  Separator,
  Edit1,
  EditIcon,
  ErrorIcon,
  BlueCheckIcon,
  CheckIcon,
  DeleteRedIcon,
  Swap,
  ASCSortIcon,
  DESCSortIcon,
  Close,
  Checked,
  UnChecked,
};
