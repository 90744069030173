import React from 'react';
import { EditIcon } from '../../../assets/icons';

export const ActionBox = ({ id,lotNo, received_quantity,showEdit, setPopUp, setPopUpData , tracking}) => {
  return (
    <div className="td-num">
      <EditIcon
        onClick={(e) => {
          e.stopPropagation();
          setPopUp(true);
          setPopUpData({ received_quantity, id,lotNo, tracking, showEdit });
        }}
      />
    </div>
  );
};
