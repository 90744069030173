import { API, METHODS } from '../constants';
import { fetchData } from '../utils';

export const vendorReceipt = async ({ data = {} }) => {
  const url = API.RECEIPT;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const validateDispatch = async ({ data = {} }) => {
  const url = API.DISPATCH_VALIDATE;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const changeDispatchStatus = async ({ data = {} }) => {
  const url = API.DISPATCH_STATUS;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const updateReceiptQty = async ({ data = {} }) => {
  const url = API.RECEIPT_UPDATE;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const packageCreationDyeing = async ({ data = {} }) => {
  const url = API.READY_FOR_PACKAGE_PICKING;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
