import { API, METHODS } from '../constants';
import { fetchData } from '../utils';

export const vendorManufacturing = async ({ data = {} }) => {
  const url = API.MANUFACTURING;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const vendorLot = async ({ data = {} }) => {
  const url = API.LOT_DETAILS;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const batchInfo = async ({ data = {} }) => {
  const url = API.BATCH_LIST;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const updatePriority = async ({ data = {} }) => {
  const url = API.UPDATE_PRIORITY;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const batchCreation = async ({ data = {} }) => {
  const url = API.BATCH_CREATION;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const batchQtyUpdate = async ({ data = {} }) => {
  const url = API.QTY_UPDATE;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
export const batchDelete = async ({ data = {} }) => {
  const url = API.DELETE_BATCH;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const readyForPack = async ({ data = {} }) => {
  const url = API.READY_TO_PACK;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const productImage = async ({ data = {} }) => {
  const url = API.PRODUCT_IMAGE;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const getPrint = async ({ data = {} }) => {
  const url = API.PRINT_LABEL;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const readyForDye = async ({ data = {} }) => {
  const url = API.READY_TO_DYE;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const getPackageList = async ({ data = {} }) => {
  const url = API.BLP_LIST;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
