import React, { useState } from 'react';
import { Reference, Swap, ASCSortIcon, DESCSortIcon } from '../../assets/icons';
import { Dropdown2 } from './Dropdown2';
import { SORTING } from '../../constants/constant';

export const TableHead = ({
  columns,
  selectedColumns,
  setSelectedColumns,
  sortInfo,
  setSortInfo,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const clickHandler = () => {
    setShowDropDown((prev) => !prev);
  };

  // Filter columns if colFilter is false

  // set sortInfo for Sorting
  const handleClick = (index, valueType) => {
    sortInfo.sortType === SORTING.DEFAULT
      ? setSortInfo({ sortType: SORTING.ASCENDING, index: index, valueType: valueType })
      : sortInfo.sortType === SORTING.ASCENDING
        ? setSortInfo({ sortType: SORTING.DESCENDING, index: index, valueType: valueType })
        : setSortInfo({ sortType: SORTING.ASCENDING, index: index, valueType: valueType });
  };
  return (
    <thead className="mb-4">
      <tr>
        {columns.map((column, colIndex) => (
          <th key={colIndex} className={column.classNameTH}>
            {column.name === 'checkbox' ? (
              <div className="dropdown">
                <button
                  className="dropdown-toggle border-0 d-flex align-items-center justify-content-center"
                  type="button"
                  id="dropdownMenuButton1"
                  onClick={clickHandler}>
                  <Reference />
                </button>
                <Dropdown2
                  list={columns}
                  show={showDropDown}
                  setShow={setShowDropDown}
                  selectedColumns={selectedColumns}
                  setSelectedColumns={setSelectedColumns}
                />
              </div>
            ) : (
              <div className="text-btn-div d-flex align-items-center justify-content-start">
                <span>{column.name}</span>
                {column.sort ? (
                  sortInfo.index === colIndex && sortInfo.sortType === SORTING.DEFAULT ? (
                    <Swap handleClick={() => handleClick(colIndex, column.valueType)} />
                  ) : sortInfo.index === colIndex && sortInfo.sortType === SORTING.ASCENDING ? (
                    <ASCSortIcon handleClick={() => handleClick(colIndex, column.valueType)} />
                  ) : (
                    <DESCSortIcon handleClick={() => handleClick(colIndex, column.valueType)} />
                  )
                ) : (
                  ''
                )}
              </div>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};
