import { GreenLabelTag, OrangeLabelTag, RedLabelTag } from '../../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { ROUTES, TEXT } from '../../../../constants';

const labels = {
  low_priority: { color: 'yellow', text: 'Low Priority', flag: <GreenLabelTag /> },
  make_first_order: { color: 'orange', text: 'Make First Order', flag: <OrangeLabelTag /> },
  priority: { color: 'red', text: 'Priority', flag: <RedLabelTag /> },
};

export const RedTile = ({ lot }) => {
  return (
    <div className="col-xl-2 col-lg-4 col-6 custom-col">
      <div className="w-100 h-100 tiles">
        <Flag label={labels} priority={lot.priority} />
        <Content lot={lot} color={'bright-orange'} />
      </div>
    </div>
  );
};

export const YellowTile = ({ lot }) => {
  return (
    <div className="col-xl-2 col-lg-4 col-6 custom-col">
      <div className="w-100 h-100 tiles">
        <Flag label={labels} />
        <Content lot={lot} color={'yellow'} />
      </div>
    </div>
  );
};

export const GreenTile = ({ lot }) => {
  return (
    <div className="col-xl-2 col-lg-4 col-6 custom-col">
      <div className="w-100 h-100 tiles">
        <Flag label={labels} />
        <Content lot={lot} color={'light-green'} />
      </div>
    </div>
  );
};

const Flag = ({ label, priority }) => {
  return (
    <div className="label-tag">
      <div
        className={`tag ${label[priority]?.color} d-flex align-items-center justify-content-start position-relative`}>
        {label[priority]?.text}
      </div>
    </div>
  );
};
const Content = ({ lot,  color }) => {
  const navigate = useNavigate();
  const clickHandler = (lot) => {
    navigate(`${ROUTES.MANUFACTURING}/${lot.url}`, {
      state: { id: lot.id },
    });
  };
  return (
    <div
      className="main-container-matrix flex-column d-flex align-items-center justify-content-center"
      onClick={() => clickHandler(lot)}>
      <div className={`lot-num ${color} w-100 d-flex align-items-center justify-content-center`}>
        {lot.lot_no}
      </div>
      <div className="num2">SKU-{lot.SKU}</div>
      <div className="progress-bar-container w-100">
        <div className="progress">
          <div
            className={`progress-bar ${color}`}
            role="progressbar"
            style={{ width: `${(lot.ready_qty / lot.total_qty) * 100}%` }}
            aria-valuenow="55"
            aria-valuemin="0"
            aria-valuemax="100"></div>
        </div>
      </div>
      <div className="num3 text-center">
        {TEXT.ORDER_STATUS}{' '}
        <span>
          {lot.ready_qty}/{lot.total_qty}
        </span>
      </div>
    </div>
  );
};
