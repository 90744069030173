import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SearchBar, Table, TitleBar, DropDown } from '../../common';
import { RECEIPT_COLUMN, ROUTES, TITLES, POPUP_TYPE, BUTTON } from '../../../constants';
import { useDebounce } from '../../../hooks';
import { vendorReceipt } from '../../../service';
import { setLoaderVisibility } from '../../../redux';
import { popup, receiptCompiler, getSortingArray } from '../../../utils';
import { Button } from '../../shared';

export const ReceiptContent = () => {
  const [receiptData, setReceiptsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort1, setSort1] = useState(RECEIPT_COLUMN[7].name);
  const [sortingArray1, setSortingArray1] = useState([]);
  const dispatch = useDispatch();

  const getReceiptsList = useDebounce((data = {}) => {
    vendorReceipt({
      data,
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          setReceiptsData(response.data);
          if (!sortingArray1.includes(sort1)) {
            setSortingArray1(
              getSortingArray({
                column: RECEIPT_COLUMN[7],
                data: response.data,
                compiler: receiptCompiler,
              }),
            );
          }
        } else {
          setReceiptsData(response.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  //  to search data on button press.
  useEffect(() => {
    let data = {};
    if (search && searchTerm) {
      data = {
        name: searchTerm,
        origin: searchTerm,
        lot_name: searchTerm,
        container_id: searchTerm,
        search: 'True',
      };
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getReceiptsList(data);
    } else if (!searchTerm && !Array.isArray(receiptData)) {
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getReceiptsList(data);
    }
  }, [search, searchTerm]);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getReceiptsList();
  }, []);
  const resetFilter = () => {
    setSort1(RECEIPT_COLUMN[7].name);
    dispatch(setLoaderVisibility(true));
    getReceiptsList();
  };
  useEffect(() => {
    let data = {};
    if (sortingArray1.length > 1) {
      dispatch(setLoaderVisibility(true));
      data = {
        state: `${sortingArray1.includes(sort1) ? sort1.toLowerCase() : ''}`,
      };
      getReceiptsList(data);
    }
  }, [sort1]);

  useEffect(() => {
    if(!searchTerm){
      dispatch(setLoaderVisibility(true));
      getReceiptsList();
    }
  }, [searchTerm]);

  return (
    <>
      <TitleBar title={[{ text: TITLES.RECEIPT, route: ROUTES.RECEIPT }]} />
      <div className="d-flex align-items-center justify-content-start gap-3 w-100">
        <SearchBar setSearchTerm={setSearchTerm} searchTerm={searchTerm} setSearch={setSearch} />
        <div className="w-50  mb-3 filter-btns-div d-flex align-items-center justify-content-start">
          <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1}></DropDown>
          {(sortingArray1.includes(sort1)) && (
            <Button
              clickHandler={resetFilter}
              className="blue-btn d-flex align-items-center justify-content-center px-3">
              {BUTTON.CLEAR_FILTERS}
            </Button>
          )}
        </div>
      </div>
      {Array.isArray(receiptData) ? (
        <Table
          columns={RECEIPT_COLUMN}
          initialData={receiptData}
          PAGE_SIZE={10}
          colFilter={false}
          to={ROUTES.RECEIPT}
          compiler={{ use: true, tool: receiptCompiler }}
        />
      ) : (
        <div>
          <h4 className="text-center px-1 py-5">{receiptData}</h4>
        </div>
      )}
    </>
  );
};
