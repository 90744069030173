import React from 'react';
import { VENDOR } from '../../../constants';
import { ProductionDetails } from './fabricManufacturing/ProductionDetails';
import { LotInfo } from './clothManufacturing/LotInfo';
import { getLocalStorageItem } from '../../../utils';

export const ManufacturingDetails = () => {
  const vendorType = getLocalStorageItem('tags')[0];
  return (
    <>
      {vendorType === VENDOR.FABRIC || vendorType === VENDOR.DYE ? (
        <ProductionDetails />
      ) : (
        <LotInfo />
      )}
    </>
  );
};
