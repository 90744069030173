import React, { useEffect, useState } from 'react';
import { setLoaderVisibility } from '../../../redux';
import { useDispatch } from 'react-redux';
import { DatePickerBox, DropDown, SearchBar, Table, TitleBar } from '../../common';
import {
  PO_COLUMN,
  ROUTES,
  TITLES,
  POPUP_TYPE,
  BUTTON,
  BILL_GENERATION_COLUMN,
} from '../../../constants';
import { poDataCompiler, popup, getSortingArray, apiDateFormat2 } from '../../../utils/';
import { vendorPurchase, createBill } from '../../../service';
import { useDebounce } from '../../../hooks';
import { Button, PopUpBox } from '../../shared';
import { useNavigate } from 'react-router-dom';

export const PoContent = () => {
  const [poData, setPoData] = useState([]);
  const [billMode, setBillMode] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort1, setSort1] = useState(PO_COLUMN[6].name);
  const [sort2, setSort2] = useState(PO_COLUMN[7].name);
  const [sortingArray1, setSortingArray1] = useState([]);
  const [sortingArray2, setSortingArray2] = useState([]);
  const [filteredColumn, setFilteredColumn] = useState(PO_COLUMN);
  const [poList, setPoList] = useState([]);

  const dispatch = useDispatch();

  const getPoList = useDebounce((data = {}) => {
    vendorPurchase({
      data,
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          setPoData(response.data);
          if (!sortingArray1.includes(sort1) && !sortingArray2.includes(sort2)) {
            setSortingArray1(
              getSortingArray({
                column: PO_COLUMN[6],
                data: response.data,
                compiler: poDataCompiler,
              }),
            );
            setSortingArray2(
              getSortingArray({
                column: PO_COLUMN[7],
                data: response.data,
                compiler: poDataCompiler,
              }),
            );
          }
        } else {
          setPoData(response.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);
  //  to search data on button press.
  useEffect(() => {
    let data = {};
    if (search && searchTerm) {
      data = {
        name: searchTerm,
        origin: searchTerm,
        lot_name: searchTerm,
        search: 'True',
      };
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getPoList(data);
    } else if (!searchTerm && !Array.isArray(poData)) {
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getPoList(data);
    }
  }, [search, searchTerm]);

  const resetFilter = () => {
    setSort1(PO_COLUMN[6].name);
    setSort2(PO_COLUMN[7].name);
    dispatch(setLoaderVisibility(true));
    getPoList();
  };

  // to load data on page load
  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getPoList();
  }, []);

  useEffect(() => {
    if (!searchTerm) {
      dispatch(setLoaderVisibility(true));
      getPoList();
    }
  }, [searchTerm]);

  useEffect(() => {
    let data = {};
    dispatch(setLoaderVisibility(true));
    if (sortingArray1.length > 1 || sortingArray2.length > 1) {
      data = {
        receipt_status: `${sortingArray2.includes(sort2) ? (sort2.toLowerCase() === 'n/a' ? false : sort2.toLowerCase()) : ''}`,
        invoice_status: `${sortingArray1.includes(sort1) ? (sort1.toLowerCase() === 'n/a' ? false : sort1.toLowerCase()) : ''}`,
      };
      getPoList(data);
    }
  }, [sort1, sort2]);

  const addPOToBill = ({ id, bill_amount, name, currency_code }) => {
    if (poList.map((po) => po.id).includes(id)) {
      setPoList((pre) => pre.filter((po) => po.id !== id));
    } else {
      setPoList((pre) => [{ name, currency_code, id, amount: bill_amount }, ...pre]);
    }
  };
  const checked = (id) => {
    if (poList.map((po) => po.id).includes(id)) {
      return true;
    }
    return false;
  };
  const handleAddBill = () => {
    billMode ? (setBillMode(0), setPoList([])) : setBillMode(1);
  };

  useEffect(() => {
    if (billMode) {
      setFilteredColumn(PO_COLUMN);
    } else {
      setFilteredColumn(PO_COLUMN.filter((col) => col.name !== '*'));
    }
  }, [billMode]);

  return (
    <>
      <TitleBar title={[{ text: TITLES.PURCHASE_ORDER, route: ROUTES.PURCHASE_ORDER }]} />
      <div className="d-flex flex-wrap align-items-center justify-content-start gap-3 w-100">
        <SearchBar setSearchTerm={setSearchTerm} searchTerm={searchTerm} setSearch={setSearch} />
        <div className="mb-3 filter-btns-div d-flex align-items-center justify-content-start">
          <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1}></DropDown>
          <DropDown dropdownMenu={sortingArray2} value={sort2} setValue={setSort2}></DropDown>
          {(sortingArray2.includes(sort2) || sortingArray1.includes(sort1)) && (
            <Button
              clickHandler={resetFilter}
              className="blue-btn d-flex align-items-center justify-content-center px-3">
              {BUTTON.CLEAR_FILTERS}
            </Button>
          )}
        </div>
      </div>
      {Array.isArray(poData) && poData?.length > 0 && (
        <div className="d-flex justify-content-end align-items-center gap-3 mb-3">
          <Button
            clickHandler={handleAddBill}
            className="blue-btn d-flex align-items-center justify-content-center px-3">
            {billMode ? BUTTON.CANCEL : BUTTON.ADD_BILL}
          </Button>
          {poList.length > 0 && (
            <Button
              clickHandler={() => setBillMode(2)}
              className="blue-btn d-flex align-items-center justify-content-center px-3">
              {BUTTON.GENERATE_BILL}
            </Button>
          )}
        </div>
      )}

      {Array.isArray(poData) ? (
        <Table
          columns={filteredColumn}
          initialData={poData}
          PAGE_SIZE={10}
          colFilter={true}
          to={billMode ? '' : `${ROUTES.PURCHASE_ORDER}`}
          compiler={{ use: true, tool: poDataCompiler }}
          func={{ addPOToBill, checked }}
          className="table-4"
        />
      ) : (
        <div>
          <h4 className="text-center px-1 py-5">{poData}</h4>
        </div>
      )}
      <PopUpBox openPopUpBox={billMode === 2}>
        <GenerateBill
          poList={poList}
          setPoList={setPoList}
          setBillMode={setBillMode}></GenerateBill>
      </PopUpBox>
    </>
  );
};

export const GenerateBill = ({ poList, setPoList, setBillMode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [date, setDate] = useState('');
  const handleClose = () => {
    setPoList([]);
    setBillMode(0);
    setDate('');
  };
  useEffect(() => {
    if (poList.length === 0) {
      setBillMode(0);
      setDate('');
    }
  }, [poList]);

  const handleDelete = (id) => setPoList((pre) => pre.filter((po) => po.id !== id));

  const handleBillCreation = () => {
    dispatch(setLoaderVisibility(true));
    createBill({
      data: { purchase_id: poList.map((po) => po.id), bill_date: apiDateFormat2(date) },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        navigate(ROUTES.BILLS);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  return (
    <>
      <h4 className="d-flex justify-content-center m-2 align-items-center">Generate Bill </h4>
      <hr />
      <div className="d-flex justify-content-start align-items-center gap-2 mb-2">
        <h6 className="d-flex px-3 mt-2 ">Date : </h6>
        <DatePickerBox value={date} setValue={setDate} clickHandler={() => {}} showSave={'hide'} />
      </div>
      <div>
        {Array.isArray(poList) && poList.length > 0 && (
          <Table
            columns={BILL_GENERATION_COLUMN}
            initialData={poList.reverse()}
            func={handleDelete}
          />
        )}
      </div>
      <hr />

      <div className="d-flex justify-content-between align-items-center">
        <h6 className="d-flex px-3 ">
          {` Total- ${poList.reduce((acc, cum) => (acc += cum.amount), 0).toFixed(2)} ${poList[0]?.currency_code}`}
        </h6>
        <div className="d-flex gap-2">
          <Button
            clickHandler={handleBillCreation}
            disabled={!date}
            className={`blue-btn ${date ? '' : 'disabled'} d-flex align-items-center justify-content-center px-3`}>
            {BUTTON.GENERATE_BILL}
          </Button>

          <Button
            clickHandler={handleClose}
            className="blue-btn d-flex align-items-center justify-content-center px-3">
            {BUTTON.CANCEL}
          </Button>
        </div>
      </div>
    </>
  );
};
