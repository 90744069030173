export function packageDataCompiler(data = []) {
  let finalData = [];
  data.forEach((obj) => {
    let packageData = {
      id: obj.id,
      BLP: obj.name,
      SKU: obj.quant_ids[0]?.product_id?.default_code,
      Batch: obj.quant_ids?.[0]?.lot_id?.name,
      SKU_Details: obj.quant_ids?.map((quant) => ({
        SKU: quant.product_id?.display_name,
        'Batch No.': quant.lot_id?.name,
        Quantity: quant.quantity + ' Unit',
      })),
      data: obj,
      box_type:
        Array.from(new Set(obj.quant_ids.map((quant) => quant.product_id.default_code))).length > 1
          ? 'Mix Box'
          : 'Single Box',
    };
    finalData.push(packageData);
  });
  return finalData;
}

export function packageDetailsBox(po) {
  let detailBoxData = [
    { name: 'Shipping Weight', col: 1, data: po?.weight },
    { name: 'Company Name', col: 1, data: 'Shakawear' },
    { name: 'Location', col: 1, data: po?.location_id?.display_name },
    { name: 'Additional reference', col: 1, data: '' },
    { name: 'GTIN Liscense Plate', col: 1, data: po?.gtin || 'N/A' },
    { name: 'Box Liscense Plate', col: 2, data: po?.name },
    { name: 'AB Style', col: 2, data: 'N/A' },
    {
      name: 'AB Color',
      col: 2,
      data: po?.quant_ids[0]?.product_id?.product_template_attribute_value_ids[0]?.name,
    },
    { name: 'AB Color Code', col: 2, data: 'N/A' },
    {
      name: 'AB Size',
      col: 2,
      data: po?.quant_ids[0]?.product_id?.product_template_attribute_value_ids[1]?.name,
    },
  ];
  return detailBoxData;
}
